<template>
  <div class="badge px-1 py-half d-block mx-1" :class="firstOne === destination ? 'badge-success' : 'badge-danger' ">
    {{ destination }}
  </div>
</template>

<script>
export default {
  props: {
    destination: {
      type: String,
      default: '',
    },
    firstOne: {
      type: String,
      default: '',
    },
  },
}
</script>

<style lang="scss" scoped>

</style>
