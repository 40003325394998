import axios from '@axios'
import endpoints from '@/http/endpoints/index'

export default {
  async getBolByProNumber(proNumber) {
    const result = await axios.get(endpoints.billOfLading.controller.getBolByProNumber(proNumber))
    return result.data.body
  },
  async getBolJobByBatchName(batchName) {
    const result = await axios.get(endpoints.billOfLading.controller.getBolJobByBatchName(batchName))
    return result.data.body
  },
  async addBolCharges(data) {
    const result = await axios.post(endpoints.billOfLading.controller.addBolCharges(), data)
    return result.data.body
  },
}
