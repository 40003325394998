<template>
  <div class="d-flex">
    <div v-if="!editMode" @click.prevent="openEditMode">
      <h6 class="m-0" role="button" :style="!isPdfGenerating ? 'color:#2981BA' : pdfTextColor">
        <span :class="value === '' ? 'text-warning' : ''">
          {{ displayValue }}
        </span>
      </h6>
    </div>
    <div v-else>
      <input ref="input" type="text"
        class="form-control"
        :value="inputValue"
        @input="updateValue"
        @blur="editMode = false"
        @keydown.enter="editMode = false"
        @keydown.escape="editMode = false"
        >
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: [String, Number],
      default: '',
    },
    isPdfGenerating: {
      type: Boolean,
      default: false,
    },
    hasColor: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      editMode: false,
      inputValue: this.value,
    }
  },
  computed: {
    displayValue() {
      if (this.value === '' || this.value === null || this.value === undefined) {
        return this.isPdfGenerating ? '' : 'Edit'
      }
      return this.value
    },
    pdfTextColor() {
      let color = 'color:black'
      if (this.hasColor?.length > 0) color = `color:${this.hasColor}` || 'color:black'
      return color
    },
  },
  methods: {
    focusInput() {
      this.$nextTick(() => {
        this.$refs.input.focus()
      })
    },
    openEditMode() {
      this.inputValue = this.value
      this.editMode = true
      this.focusInput()
    },
    updateValue(event) {
      this.inputValue = event.target.value
      this.$emit('input', this.inputValue)
    },
  },
}
</script>

<style scoped>
</style>
