<template>
  <div class="container-fluid card" style="max-width: 1400px;">
    <div class="row justify-content-center">
      <div class="col-12 border-bottom pb-1 pt-half">
        <div class="card">
          <dx-util-tab-panel id="bol" :animation-enabled="true" :defer-rendering="true" :show-nav-buttons="true">
            <dx-util-panel-item title="BOL Generation" icon="icon icon-psh-package-3">
              <template #default>
                <div>
                  <div class="d-flex p-1">
                  <dx-util-text-box id="bolNumber" ref="bolNumber" v-model="bolNumber" label="BOL / LOAD #" label-mode="floating" class="mr-1" @enterKey="generateBOL" />
                  <dx-util-text-box v-model="dropOff.appointmentId" label="Appointment ID" label-mode="floating" class="mr-1" @enterKey="generateBOL" />
                  <dx-util-button id="bolGenerator" icon="icon icon-psh-package-3" text="Generate BOL" type="success" class="mr-1 mt-half" style="width:160px" @click="generateBOL" />
                  <dx-util-button id="pdfGenerator" :disabled="!showBOL" icon="pdffile" text="Generate PDF" type="success" class="mr-1 mt-half" style="width:160px" @click="generatePDF" />
                  <dx-util-button id="bolUploader" icon="icon icon-psh-package-3" text="Upload BOL" type="default" class="mr-1 mt-half" style="width:160px" @click="uploadSignedBOL" />
                </div>
                <div>
                  <transition name="slide-down">
                    <vue-html2pdf
                      v-if="showBOL"
                      ref="html2Pdf"
                      :show-layout="false"
                      :float-layout="false"
                      :enable-download="true"
                      :preview-modal="false"
                      :filename="`bol-${bolNumber}`"
                      :pdf-quality="2"
                      :margin="[0, 0, 0, 0]"
                      :manual-pagination="false"
                      :paginate-elements-by-height="10000"
                      pdf-format="letter"
                      pdf-orientation="portrait"
                      :pdf-content-width="isPdfGenerating ? '800px' : '1200px'"
                      @hasDownloaded="hasGenerated"
                  >
                    <section ref="bolContent" slot="pdf-content" class="p-1" :style="isPdfGenerating ? 'color:black!important' : ''">
                        <div ref="bolContent" class="col-12 text-center py-1 px-half my-element">
                          <div class="d-flex justify-content-center">
                            <div class="flex-grow-1"></div>
                            <h2 class="my-0" :style="isPdfGenerating ? 'color:black' :''">
                              BILL OF LADING
                            </h2>
                            <div class="flex-grow-1"></div>
                          </div>
                            <small class="my-0">Non-Negotiable</small>
                            <div class="d-flex align-items-center justify-content-center ">
                              <small class="my-0">
                                Generated At: {{ bolDate }}
                              </small>
                          </div>
                        </div>
                        <table>
                          <thead>
                            <th class="text-center">
                              BOL/LOAD #{{ bolNumber }}
                            </th>
                            <th class="text-center">
                              BOL BARCODE
                            </th>
                          </thead>
                          <tbody>
                            <tr>
                              <td>
                                <div class="row text-left my-half">
                                  <div class="col-6">
                                    Pickup Carrier SCAC
                                  </div>
                                  <div class="col-6">
                                    <editable-input-box v-model="carrier.scac" :is-pdf-generating="isPdfGenerating" />
                                  </div>
                                </div>
                                <div class="row text-left my-half">
                                  <div class="col-6">
                                    Pickup Carrier Name
                                  </div>
                                  <div class="col-6">
                                    <editable-input-box v-model="carrier.name" :is-pdf-generating="isPdfGenerating" />
                                  </div>
                                </div>
                                <div class="row text-left my-half">
                                  <div class="col-6">
                                    Shipper Reference #
                                  </div>
                                  <div class="col-6">
                                    <editable-input-box v-model="carrier.reference" :is-pdf-generating="isPdfGenerating" />
                                  </div>
                                </div>
                                <div class="row text-left my-half align-items-center">
                                  <div class="col-6">
                                    Order #
                                  </div>
                                  <div class="col-6">
                                    <h6 :style="isPdfGenerating ? 'color:black' :''">
                                      {{ bolNumber }}
                                    </h6>
                                  </div>
                                </div>
                              </td>
                              <td></td>
                            </tr>
                          </tbody>
                        </table>
                        <table class="my-2">
                          <thead>
                            <th class="text-center">
                              PICKUP
                            </th>
                            <th class="">
                              <div class="d-flex justify-content-center align-items-center">
                                PICKUP DETAILS
                                <editable-input-box v-model="pickUp.details" class="ml-1" :has-color="'white'" :is-pdf-generating="isPdfGenerating" />
                              </div>
                            </th>
                          </thead>
                          <tbody>
                            <tr>
                              <td>
                                <div v-if="!isPdfGenerating">
                                  <dx-util-select-box
                                    v-model="selectedPickUpAddress"
                                    :data-source="pickUpAddresses"
                                    display-expr="text"
                                    value-expr="id"
                                    item-template="item"
                                    label-mode="floating"
                                    label="Ship From"
                                    class="mb-half flex-grow-1"
                                    :show-clear-button="true"
                                    @value-changed="fillPickUpAddress"
                                  >
                                    <template #item="{ data }">
                                      <div v-html="data.textHtml" />
                                    </template>
                                    <dx-util-validator>
                                      <dx-util-required-rule
                                        message="Pickup address is a required field"
                                      />
                                    </dx-util-validator>
                                  </dx-util-select-box>
                                </div>
                                <div class="row text-left my-half">
                                  <div class="col-4">
                                    Name
                                  </div>
                                  <div class="col-8">
                                    <editable-input-box v-model="pickUp.name" :is-pdf-generating="isPdfGenerating" />
                                  </div>
                                </div>
                                <div class="row text-left my-half">
                                  <div class="col-4">
                                    Address
                                  </div>
                                  <div class="col-8">
                                    <editable-input-box v-model="pickUp.address" :is-pdf-generating="isPdfGenerating" />
                                  </div>
                                </div>
                                <div class="row text-left my-half">
                                  <div class="col-4">
                                    City/State/Zip
                                  </div>
                                  <div class="col-8">
                                    <editable-input-box v-model="pickUp.cityStateZip" :is-pdf-generating="isPdfGenerating" />
                                  </div>
                                </div>
                                <div class="row text-left my-half align-items-center">
                                  <div class="d-flex align-items-center px-1">
                                    From: <editable-input-box v-model="pickUp.from" class="ml-half" :is-pdf-generating="isPdfGenerating" />
                                  </div>
                                  <div class="d-flex align-items-center">
                                    To: <editable-input-box v-model="pickUp.to" class="ml-half" :is-pdf-generating="isPdfGenerating" />
                                  </div>
                                </div>
                                <div class="row text-left my-half">
                                  <div class="col-4">
                                    Contact
                                  </div>
                                  <div class="col-8">
                                    <editable-input-box v-model="pickUp.contact" :is-pdf-generating="isPdfGenerating" />
                                  </div>
                                </div>
                              </td>
                              <td>
                                <div class="row text-left my-half">
                                  <div class="col-4">
                                    Appointment
                                  </div>
                                  <div class="col-8">
                                    <editable-input-box v-model="pickUp.appointment" :is-pdf-generating="isPdfGenerating" />
                                  </div>
                                </div>
                                <div class="row text-left my-half">
                                  <div class="col-4">
                                    Loading Type
                                  </div>
                                  <div class="col-8">
                                    <editable-input-box v-model="pickUp.loadingType" :is-pdf-generating="isPdfGenerating" />
                                  </div>
                                </div>
                                <div>
                                  <div class="black-header">
                                    INSTRUCTIONS
                                  </div>
                                  <div>
                                    <dx-util-text-area v-model="pickUp.instructions" :auto-resize-enabled="true" :input-attr="{ style: { color: 'red'} }" :class="isPdfGenerating ? 'bg-white' : ''" />
                                  </div>
                                </div>
                              </td>
                            </tr>
                          </tbody>
                          <thead>
                            <th class="text-center">
                              DROP OFF
                            </th>
                            <th class="text-center">
                              DROP OFF DETAIL
                            </th>
                          </thead>
                          <tbody>
                            <tr>
                              <td>
                                <div v-if="!isPdfGenerating">
                                  <dx-util-select-box
                                    v-model="selectedAmazonAddress"
                                    :data-source="amazonAddresses"
                                    display-expr="text"
                                    value-expr="id"
                                    search-mode="contains"
                                    :show-data-before-search="false"
                                    :search-timeout="200"
                                    :search-enabled="true"
                                    search-expr="code"
                                    :min-search-length="0"
                                    label-mode="floating"
                                    label="Amazon Address"
                                    class="mb-2"
                                    :show-clear-button="true"
                                    @value-changed="fillAddressFields"
                                  />
                                </div>
                                <div class="row text-left my-half">
                                  <div class="col-4">
                                    Name
                                  </div>
                                  <div class="col-8">
                                    <editable-input-box v-model="dropOff.name" :is-pdf-generating="isPdfGenerating" />
                                  </div>
                                </div>
                                <div class="row text-left my-half">
                                  <div class="col-4">
                                    Address
                                  </div>
                                  <div class="col-8">
                                    <editable-input-box v-model="dropOff.address" :is-pdf-generating="isPdfGenerating" />
                                  </div>
                                </div>
                                <div class="row text-left my-half">
                                  <div class="col-4">
                                    City/State/Zip
                                  </div>
                                  <div class="col-8">
                                    <editable-input-box v-model="dropOff.cityStateZip" :is-pdf-generating="isPdfGenerating" />
                                  </div>
                                </div>
                                <div class="row text-left my-half">
                                  <div class="col-4">
                                    Contact
                                  </div>
                                  <div class="col-8">
                                    <editable-input-box v-model="dropOff.contact" :is-pdf-generating="isPdfGenerating" />
                                  </div>
                                </div>
                              </td>
                              <td>
                                <div class="row text-left my-half">
                                  <div class="col-4">
                                    Appointment
                                  </div>
                                  <div class="col-8">
                                    <h6 :style="isPdfGenerating ? 'color:black' :''">
                                      {{ dropOff.appointmentId }}
                                    </h6>
                                  </div>
                                </div>
                                <div class="row text-left my-half">
                                  <div class="col-4">
                                    Loading Type
                                  </div>
                                  <div class="col-8">
                                    <editable-input-box v-model="dropOff.loadingType" :is-pdf-generating="isPdfGenerating" />
                                  </div>
                                </div>
                                <div>
                                  <div class="black-header">
                                    INSTRUCTIONS
                                  </div>
                                  <div>
                                    <dx-util-text-area v-model="dropOff.instructions" :auto-resize-enabled="true" :class="isPdfGenerating ? 'bg-white' : ''" />
                                  </div>
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        <div v-if="!isPdfGenerating" class="py-half d-flex align-items-center">
                          <div class="px-1">
                            <dx-util-text-box
                              v-model="batchName"
                              placeholder="Batch name"
                              @enterKey="addJob"
                            />
                          </div>
                          <div class="px-1">
                            <dx-util-button icon="add" text="ADD JOB TO BOL" type="success" @click="addJob" />
                          </div>
                        </div>
                        <div v-for="(jobList, index) in jobBatches" :key="index">
                          <table id="jobs" style="margin-top:80px">
                            <thead>
                              <tr>
                                <th v-if="!isPdfGenerating && !isIndividualTenant" style="width:5%">
                                  ACC#
                                </th>
                                <th style="width:25%">
                                  JOB NO
                                </th>
                                <th style="width:20%">
                                  AMAZON ID
                                </th>
                                <th v-if="!isPdfGenerating" style="width:10%">
                                  DESTINATION
                                </th>
                                <th>CTNS</th>
                                <th>PALLET</th>
                                <th>GROSS WEIGHT LBS</th>
                                <th v-if="!isPdfGenerating" class="narrower"></th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr v-for="job in jobList" :key="job.jobNo">
                                <td v-if="!isPdfGenerating && !isIndividualTenant" class="">
                                  {{ job.accountNo }}
                                </td>
                                <td class="">
                                  <editable-input-box v-model="job.jobNo" :is-pdf-generating="isPdfGenerating" />
                                </td>
                                <td class="">
                                  <editable-input-box v-model="job.amazonId" :is-pdf-generating="isPdfGenerating" />
                                </td>
                                <td v-if="!isPdfGenerating" class="">
                                  <destination-box :destination="job.destCode" :first-one="jobs[0].destCode" />
                                </td>
                                <td class="">
                                  <editable-input-box v-model="job.cartonCount" :is-pdf-generating="isPdfGenerating" />
                                </td>
                                <td class="">
                                  <editable-input-box v-model="job.palletCount" :is-pdf-generating="isPdfGenerating" />
                                </td>
                                <td class="">
                                  <div class="d-flex justify-content-between">
                                    <editable-input-box v-model="job.itemCount" :is-pdf-generating="isPdfGenerating" />
                                    <p-icon v-if="job.itemCount == 0" name="bi-exclamation-circle-fill" color="red" />
                                  </div>
                                </td>
                                <td v-if="!isPdfGenerating" class="text-center ">
                                  <p-icon name="bi-trash" color="danger" role="button" @click.native="removeJob(job.jobNo)" />
                                </td>
                              </tr>
                            </tbody>
                          </table>
                          <div v-if="index !== jobBatches.length-1" class="html2pdf__page-break" />
                        </div>
                        <table class="mt-2">
                          <thead>
                            <tr>
                              <th colspan="2" class="text-center">
                                NOTE: Carrier accepts liability as a common carrier pursuant to 49 USC 14706.
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td style="font-size:10px;">
                                <span>
                                  RECEIVED, subject to individually determined rates or contracts that have
                                  been agreed upon in writing between the carrier and shipper or shipper’s broker,
                                  if applicable, otherwise to the rates classifications and rules that have been
                                  established by the carrier and are available to the shipper, on request, and
                                  to all applicable state and federal regulations.
                                </span>
                              </td>
                              <td style="font-size:10px;">
                                <span>
                                  For Freight Collect Shipments, the carrier shall not make
                                  delivery of this shipment without payment of freight and all other lawful charges.
                                </span>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <div class="black-header">
                                  SHIPPER SIGNATURE / DATE
                                </div>
                                <div class="p-half" style="font-size:10px;">
                                  <span>
                                    This is to certify that the above named materials are properly classified,
                                    described, packaged, marked and labeled; and are in proper condition for
                                    transportation according to the applicable regulations of the U.S. DOT.
                                  </span>
                                </div>
                                <div class="p-half signature-border" style="height:20px"></div>
                                <div class="p-half">
                                  <small>Shipper Signature</small>
                                </div>
                              </td>
                              <td>
                                <div class="black-header">
                                  CARRIER SIGNATURE / DATE
                                </div>
                                <div class="p-half" style="font-size:10px;">
                                  <span>
                                    Carrier acknowledges receipt of packages and required placards.
                                    Property described above is received in good order, count, and condition, except as noted.
                                  </span>
                                </div>
                                <div class="p-half signature-border" style="height:20px"></div>
                                <div class="p-half">
                                  <small>Carrier Signature</small>
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                    </section>
                  </vue-html2pdf>
                  </transition>
                </div>
                <div v-if="showUpload">
                  <service-folder-file-manager
                    ref="refServiceFolderFileManager"
                    :company-id="userCompanyId"
                    :upload-document-class="'BATCH_PRO_NUMBER'"
                    :upload-document-classes="tabs"
                    :entity-folder-id="bolNumber"
                    :service-folder="'BOL_OUTBOUND'"
                  />
                </div>
                </div>
              </template>
            </dx-util-panel-item>
            <dx-util-panel-item title="Amazon Freight" icon="icon icon-psh-package-4">
              <template #default>
                <amazon-freight-bol />
              </template>
            </dx-util-panel-item>
            <dx-util-panel-item title="Charge Generation" icon="bi bi-cash-coin">
              <template #default>
                <div>
                  <div class="d-flex p-1">
                    <dx-util-text-box id="bolNumber2" ref="bolNumber2" v-model="bolNumber" label="BOL / LOAD #" label-mode="floating" class="mr-1" @enterKey="generateCharges" />
                    <dx-util-number-box id="bolTotalCost" ref="bolTotalCost" v-model="bolTotalCost" label="Total Cost" label-mode="floating" class="mr-1" @enterKey="generateCharges" />
                    <dx-util-select-box id="costGroup" v-model="costGroupId" :data-source="costGroups" label="Cost Group" label-mode="floating" display-expr="text" value-expr="value" class="mr-1" />
                    <dx-util-button id="chargeGenerator" icon="bi bi-cash-coin" text="Create Charges" type="success" class="mr-1 mt-half" style="width:160px" @click="generateCharges" />
                    <dx-util-button icon="save" text="Save Charges" type="default" class="mr-1 mt-half" @click="saveCharges" />
                  </div>
                  <div>
                    <transition name="slide-down">
                      <div v-if="showBOLCharges">
                      <section class="p-1">
                          <div rclass="col-12 text-center py-1 px-half my-element">
                            <div class="d-flex justify-content-center">
                              <h2 class="pb-4">
                                BILL OF LADING CHARGE DISTRIBUTION
                              </h2>
                            </div>
                          </div>
                          <table id="charges">
                            <thead>
                              <tr>
                                <th v-if="!isPdfGenerating" style="width:5%">
                                  ACC#
                                </th>
                                <th style="width:25%">
                                  JOB NO
                                </th>
                                <th v-if="!isPdfGenerating" style="width:10%">
                                  DESTINATION
                                </th>
                                <th>CTNS</th>
                                <th>PALLET</th>
                                <th>SKU COUNT</th>
                                <th>ITEM COUNT</th>
                                <th>EST. GROSS WEIGHT LBS</th>
                                <th>CHARGE ($)</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr v-for="job in jobs" :key="job.jobNo">
                                <td>
                                  {{ job.accountNo }}
                                </td>
                                <td>
                                  {{ job.jobNo }}
                                </td>
                                <td>
                                  <destination-box :destination="job.destCode" :first-one="jobs[0].destCode" />
                                </td>
                                <td>
                                  {{ job.cartonCount }}
                                </td>
                                <td>
                                  {{ job.palletCount }}
                                </td>
                                <td>
                                  {{ job.mskuCount }}
                                </td>
                                <td>
                                  {{ job.itemCount }}
                                </td>
                                <td>
                                  {{ job.totalWeight }}
                                </td>
                                <td>
                                  <dx-util-number-box v-model="chargeMap[job.batchId]" :step="0" :format="'$ #,##0.##'" />
                                </td>
                              </tr>
                            </tbody>
                          </table>
                      </section>
                    </div>
                    </transition>
                  </div>
                </div>
              </template>
            </dx-util-panel-item>
          </dx-util-tab-panel>
        </div>
      </div>
    </div>
    <dx-util-popover
      :width="300"
      :visible="false"
      target="#bolGenerator"
      position="bottom"
      show-event="dxhoverstart"
      hide-event="dxhoverend"
    >
      Please enter BOL number and Appointment ID to create a BOL form.
    </dx-util-popover>
    <dx-util-popover
      :width="300"
      :visible="false"
      target="#chargeGenerator"
      position="bottom"
      show-event="dxhoverstart"
      hide-event="dxhoverend"
    >
      Please enter BOL number and total charge amount to create charge generation form.
    </dx-util-popover>
    <dx-util-popover
      :width="300"
      :visible="false"
      target="#bolUploader"
      position="bottom"
      show-event="dxhoverstart"
      hide-event="dxhoverend"
    >
      Upload the signed copy of the BOL. It will automatically match the copy with the batches listed in the BOL.
    </dx-util-popover>
    <dx-util-popover
      :width="300"
      :visible="false"
      target="#pdfGenerator"
      position="bottom"
      show-event="dxhoverstart"
      hide-event="dxhoverend"
    >
    Click to generate pdf copy of the current BOL.
    </dx-util-popover>
  </div>
</template>

<script>
import { Notify } from '@/@robustshell/utils'
import moment from 'moment'
import VueHtml2pdf from 'vue-html2pdf'
import commonService from '@/http/requests/common/commonEntityService'
import costGroupService from '@/http/requests/finance/costGroupService'
import billOfLadingService from '@/http/requests/outbound/billOfLadingService.js'
import useCurrentUser from '@/libs/app/current-user'
import { confirm } from 'devextreme/ui/dialog'
import EditableInputBox from './EditableInputBox.vue'
import DestinationBox from './DestinationBox.vue'

export default {
  components: {
    'editable-input-box': EditableInputBox,
    'destination-box': DestinationBox,
    VueHtml2pdf,
    'amazon-freight-bol': () => import('./components/AmazonFreightBOL.vue'),
    'service-folder-file-manager': () => import('@components/service-folder/ServiceFolderFileManager.vue'),
  },
  data() {
    return {
      bolNumber: null,
      bolDate: '',
      bolContent: {},
      bolTotalCost: null,
      batchName: '',
      isPdfGenerating: false,
      showBOL: false,
      showUpload: false,
      showBOLCharges: false,
      carrier: {
        name: '',
        scac: '',
        reference: '',
      },
      pickUp: {
        name: '',
        address: '',
        cityStateZip: '',
        from: '',
        to: '',
        details: '',
        contact: '',
        appointment: 'FIRST COME FIRST SERVE',
        loadingType: 'LIVE LOAD',
      },
      dropOff: {
        name: '',
        address: '',
        cityStateZip: '',
        contact: '',
        appointmentId: null,
        loadingType: 'LIVE LOAD',
      },
      amazonAddresses: [],
      selectedAmazonAddress: null,
      pickUpAddresses: [],
      selectedPickUpAddress: null,
      jobs: [],
      unitCost: 0,
      chargeMap: {},
      costGroups: [],
      costGroupId: null,
      jobBatches: [],
      tabs: ['BATCH_PRO_NUMBER'],
    }
  },
  watch: {
    unitCost() {
      this.jobs.forEach(job => {
        this.chargeMap[job.batchId] = job.itemCount * this.unitCost || 0
      })
    },
  },
  setup() {
    const {
      userCompanyId, userAccountNo, userFullname, userWarehouseId, isIndividualTenant,
    } = useCurrentUser()
    return {
      userCompanyId, userAccountNo, userFullname, userWarehouseId, isIndividualTenant,
    }
  },
  async mounted() {
    this.getCostGroups()
    await this.setBolNumberFocus()
    await this.generateToday()
    await this.getPickUpAddresses()
    await this.getAmazonAddresses()
  },
  methods: {
    async getBOLData() {
      this.bolContent = await billOfLadingService.getBolByProNumber(this.bolNumber)
      this.jobs = this.bolContent.batches
      this.jobBatches = this.createChunks(this.jobs)
      // this.jobs = this.bolContent.batches
      this.dropOff.name = this.nullCheck(this.bolContent?.dropAddress?.name)
      this.dropOff.address = `${this.nullCheck(this.bolContent?.dropAddress?.line1)}
                              ${this.nullCheck(this.bolContent?.dropAddress?.line2)}`
      this.dropOff.cityStateZip = `${this.nullCheck(this.bolContent?.dropAddress?.city)}
                                   ${this.nullCheck(this.bolContent?.dropAddress?.state)}
                                   ${this.nullCheck(this.bolContent?.dropAddress?.zipcode)}`
      const date = moment()
      const formattedDate = date.format('MM/DD/YYYY')
      this.pickUp.from = `${formattedDate} 08:00 EDT`
      this.pickUp.to = `${formattedDate} 09:30 EDT`
      this.pickUp.contact = this.userFullname
      this.selectedAmazonAddress = this.bolContent.dropAddress.id
    },
    createChunks(jobs) {
      const firstChunkSize = 10
      const nextChunkSize = 30

      const chunks = []
      let startIndex = 0

      chunks.push(jobs.slice(startIndex, startIndex + firstChunkSize))
      startIndex += firstChunkSize

      while (startIndex + nextChunkSize < jobs.length) {
        chunks.push(jobs.slice(startIndex, startIndex + nextChunkSize))
        startIndex += nextChunkSize
      }

      if (startIndex < jobs.length) {
        chunks.push(jobs.slice(startIndex))
      }
      return chunks
    },
    getCostGroups() {
      costGroupService.fetchAllList().then(result => {
        const { data } = result
        this.costGroups.splice(0, this.costGroups.length)
        data.forEach(element => {
          this.costGroups.push({ value: element.id, text: element.name })
          if (element.name === 'Amazon FTL Freight') {
            this.costGroupId = element.id
          }
        })
      })
    },
    async generateBOL() {
      if (this.bolNumber === null || this.dropOff.appointmentId === null) {
        Notify.warning('Please enter the BOL Number and Appointment ID to generate a BOL')
        this.setBolNumberFocus()
        return
      }
      await this.getBOLData()
      this.showBOLCharges = false
      this.showBOL = true
    },
    async generateCharges() {
      if (this.bolNumber === null) {
        Notify.warning('Please enter the BOL Number and total cost to generate charges')
        this.setBolNumber2Focus()
        return
      }
      if (this.bolTotalCost <= 0) {
        Notify.warning('Please enter the BOL Number and total cost to generate charges')
        this.setTotalCostFocus()
        return
      }
      await this.getBOLData()
      this.calculateUnitCharge()
      this.jobs.forEach(job => {
        const charges = {}
        this.chargeMap[job.batchId] = job.itemCount * this.unitCost || 0
        this.chargeMap = { ...charges }
      })
      this.showBOL = false
      this.showBOLCharges = true
    },
    saveCharges() {
      if (this.costGroupId === null) {
        Notify.warning('Please select a Cost Group')
        return
      }
      const batchCharges = Object.entries(this.chargeMap).map(([batchId, charge]) => ({ batchId, charge }))
      if (batchCharges.length === 0) {
        Notify.warning('No charge has been found. Please fill in the form and click Create Charges first')
        return
      }
      const payload = {
        batchCharges,
        warehouseId: this.userWarehouseId,
        bol: this.bolNumber,
        costGroupId: this.costGroupId,
      }
      const confirmResult = confirm(
        'Confirm to create charges for the selected shipment batches. <br> You can see the generated charges in Account > Accounting > Charges page after creation.',
        'Confirmation',
      )
      confirmResult.then(async dialogResult => {
        if (dialogResult) {
          billOfLadingService.addBolCharges(payload).then(() => {
            Notify.success('BOL charges are added successfully.')
            this.jobs = []
            this.chargeMap = {}
            this.bolNumber = null
            this.bolTotalCost = null
          })
        }
      })
    },
    async calculateUnitCharge() {
      if (this.bolTotalCost <= 0) {
        Notify.warning('Please enter the total cost')
        this.setTotalCostFocus()
        return
      }
      const itemCount = this.jobs.reduce((sum, obj) => sum + obj.itemCount, 0)
      const costPerItem = this.bolTotalCost / itemCount
      this.unitCost = costPerItem.toFixed(2).toString()
    },
    generatePDF() {
      this.isPdfGenerating = true
      this.$refs.html2Pdf.generatePdf()
    },
    hasGenerated() {
      this.isPdfGenerating = false
    },
    uploadSignedBOL() {
      this.showBOL = false
      if (this.bolNumber === null) {
        Notify.warning('Please enter the BOL Number to upload a file')
        this.setBolNumberFocus()
        // eslint-disable-next-line no-useless-return
        return
      }
      this.showUpload = true
    },
    setBolNumberFocus() {
      this.$refs.bolNumber.instance.focus()
    },
    setBolNumber2Focus() {
      this.$refs.bolNumber2.instance.focus()
    },
    setTotalCostFocus() {
      this.$refs.bolTotalCost.instance.focus()
    },
    generateToday() {
      const date = moment()
      const formattedDate = date.format('YYYY-MM-DD')
      this.bolDate = formattedDate
    },
    updateBOL() {
      this.isEditable = false
    },
    nullCheck(value) {
      return value != null && value !== undefined ? value : ''
    },
    getAmazonAddresses() {
      commonService.getAmazonAddresses().then(res => {
        const { data } = res
        data.body.forEach((el, i) => {
          data.body[i].text = `${el.countryCode}:${el.code} - (${el.city}/${el.state})`
        })
        this.amazonAddresses.length = 0
        this.amazonAddresses = [...data.body]
      })
    },
    fillAddressFields(e) {
      const address = this.amazonAddresses.filter(el => el.id === e.value)[0]
      Object.assign(this.dropOff, {
        name: address.name,
        address: `${this.nullCheck(address.line1)} ${this.nullCheck(address.line2)}`,
        cityStateZip: `${address.city} ${address.state} ${address.zipcode}`,
        country: address.countryCode,
      })
      this.dropOff.name = address.name
    },
    getPickUpAddresses() {
      this.pickUpAddresses.length = 0
      commonService
        .fetchAddressesByTenantAndCompany(this.userCompanyId)
        .then(result => {
          const { data } = result
          data.forEach(item => {
            let suiteNumber = ''
            if (item.commonHub.commonHubType === 'warehouse') {
              suiteNumber = `Suite # ${this.userAccountNo}`
            }
            const line = `${this.nullCheck(item.line1)} ${this.nullCheck(item.line2)} ${this.nullCheck(item.line3)}`
            const addressStringHtml = `${item.name}<br />${line} ${suiteNumber}<br />${item.city}, ${item.state} ${item.zipcode}`
            const addressString = `${item.name} ${line} ${suiteNumber} ${item.city}, ${item.state} ${item.zipcode}`
            this.pickUpAddresses.push({
              id: item.id,
              textHtml: addressStringHtml,
              text: addressString,
              raw: item,
              primary: item.primary,
            })
          })
        })
        .then(() => {
          this.$nextTick(() => {
            if (this.pickUpAddresses.length === 1) {
              this.selectedPickUpAddress = this.pickUpAddresses[0].id
            } else {
              this.selectedPickUpAddress = this.pickUpAddresses.filter(el => el.primary === true)[0].id
            }
            const address = this.pickUpAddresses.filter(el => el.primary === true)[0]
            this.fillPickUpAddress(address)
          })
        })
    },
    fillPickUpAddress(address) {
      this.pickUp.name = address.raw.name
      this.pickUp.address = `${address.raw.line1} ${address.raw.line2 || ''} ${address.raw.line3 || ''} Suite #${this.userAccountNo}`
      this.pickUp.cityStateZip = `${address.raw.city} ${address.raw.state} ${address.raw.zipcode}`
    },
    async addJob() {
      const jobNumbers = this.jobs.map(el => el.jobNo)
      if (jobNumbers.includes(this.batchName)) {
        Notify.warning('The batch is already in the job list')
        return
      }
      const jobDetails = await billOfLadingService.getBolJobByBatchName(this.batchName)
      this.jobs.push(jobDetails.batches[0])
    },
    removeJob(e) {
      this.jobs = this.jobs.filter(el => el.jobNo !== e)
      this.jobBatches = this.createChunks(this.jobs)
    },
    removeCharge(e) {
      const batchIdForDelete = this.jobs.filter(el => el.jobNo === e)[0].batchId
      delete this.chargeMap[batchIdForDelete]
      this.jobs = this.jobs.filter(el => el.jobNo !== e)
    },
  },
}
</script>

<style lang="scss">
#bol {
  .dx-tab-selected {
    background-color: #2A8ABA;
  }
  .dx-tabs {
    border-radius: 4px 4px 0 0;
  }
  .dx-tab {
    border-radius: 4px 4px 0 0;
  }
}
</style>

<style lang="scss" scoped>

table {
  width: 100%;
  table-layout: fixed;
  border-collapse: collapse;
}

th {
  background-color: rgba(17, 1, 1, 0.9);
  color: azure;
  padding-top: 5px;
  padding-bottom: 5px;
  white-space: nowrap;
}

th, td {
  border: 1px solid rgba(34, 32, 32, 0.9);;
  padding: 5px;
  text-align: left;
  font-size: 12px;
}

td {
  width: calc(100% / 2);
  vertical-align: top;
}

// #jobs tr:nth-child(n+13):nth-child(35n+13) {
//   page-break-after: always;
//   page-break-inside: avoid;
//   background-color: pink;
// }

#charges td {
  font-size: 14px;
}
.form-border {
  border: 1px solid rgba(197, 185, 185, 0.9);
  padding: 10px;
}

th.narrow {
  width: 15%;
}
th.narrower {
  width: 5%;
}
.signature-border {
  border-bottom: 1px solid black;
  margin-right: 100px;
}
.my-content {
  page-break-before: always;
}
.bg-white {
  background-color: white;
}
.slide-down-enter-active,
.slide-down-leave-active {
  transition: max-height 0.3s ease-in-out;
}

.slide-down-enter-to,
.slide-down-leave {
  overflow: hidden;
  max-height: 1000px;
}

.slide-down-enter,
.slide-down-leave-to {
  overflow: hidden;
  max-height: 0;
}
small {
  font-size: x-small;
}

</style>
